/* .label-color :is(#combo-box-demo) {
    color: black;
} */

#combo-box-demo-label {
    color: black;
    caret-color: blue;
}

.MuiIconButton-root>svg:nth-child(1) {
    color: black;
}

/* .color-change:hover {
    border-color: blue;
} */

/* div.MuiFormControl-root:nth-child(1):hover {
    border-color: yellow;
} */

/* .MuiSlider-thumb:hover {
    color: green !important;
} */
/* #combo-box-demo:hover {
    background-color: blue;
} */
/* .MuiAutocomplete-popper{
background-color: blue;
} */