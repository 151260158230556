.nav-div-li .active {
    border-bottom: 1px groove black;
    transition: all 0.4s ease-in-out;
    font-weight: 500;
}
.nav-div-liWhite .active {
    border-bottom: 1px groove whitesmoke;
    transition: all 0.4s ease-in-out;
    font-weight: 500;
}
.nav-div-li p:hover{
    /* color: brown;
    opacity: 50%; */
    font-weight: 600;
}
