#displayName-label,
#PhoneNumberProfile-label,
#emailProfile-label,
#ChangePasswordProfile-label,
#ConfirmPasswordProfile-label,
#dialougeEmail-label,
#dialougePass-label {
    color: rgb(78, 78, 65);
    caret-color: blue;
}

.Mui-disabled:not(label):hover {
    cursor: not-allowed;
}