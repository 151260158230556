
/* .hero-background {

    background-size: cover;
    background-position: center;
    height: 60vh;
    background-repeat: no-repeat;

} */
.outside-div {
    background-image: url('/src/Images/hero/Group-1118.png');
    object-fit: cover;
    background-size: cover;
    background-position: center;
    height: 500px;
    background-repeat: no-repeat;

}

.search-bar-3-options {
    position: relative;
    top: 300px;
    /* bottom: -30%; */
}

@media only screen and (max-width: 600px)  {
    .outside-div{
        height: 300px;
    }
}