body {
    background: #eee;
    font-size: 14px;
    /* color: ; */
    margin: 0;
    padding: 0;
}

.swiper {
    width: 75%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.swiper-slide img {
    display: block;
    width: 100%;
}